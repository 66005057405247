import { graphql, useStaticQuery } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React, { useRef } from "react";
import Layout from "../components/layout";
import Hero from "../components/Header1";
import CustomerTestimonials from "../components/CustomerTestimonials";
import CardLayout from "../components/CardLayout";
import CardLayout2 from "../components/CardLayout2";
import { GatsbyImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import BlogListing from "../components/BlogListing";
import ContactLayout from "../components/ContactLayout";
import ContactLayout2 from "../components/ContactLayout2";
import SectionLayout from "../components/SectionLayout";
import SectionLayout2 from "../components/SectionLayout2";
import SectionLayout3 from "../components/SectionLayout3";
import SectionLayout4 from "../components/SectionLayout4";
import SectionLayout5 from "../components/SectionLayout5";
import BlogListingAll from "../components/BlogListingAll";
import CtaBanner from "../components/CtaBanner";
import OurTeam from "../components/OurTeam";
import Header2 from "../components/Header2";
import RecommendedBlogs from "../components/RecommandedBlogs";
import CareerListing from "../components/CareerListing";

const PageTemplate = ({
	data: { wpPage, site, allWpPost, reviews },
}) => {
	const siteUrl = site.siteMetadata.siteUrl;

	const componentMap = {
		Page_Pagefieldsgroup_Components_Header1: (data) => (
			<Hero
				title={data?.heading}
				description={data?.description}
				button={data?.link}
				logos={data?.logos}
				image={data?.image?.localFile?.childImageSharp?.gatsbyImageData}
			/>
		),
		Page_Pagefieldsgroup_Components_Header2: (data) => (
			<Header2
				heading={data?.heading}
				button={data?.link}
				overlayColour={data?.overlayColor}
				backgroundImage={
					data?.backgroundImage?.localFile?.childImageSharp?.gatsbyImageData
				}
			/>
		),
		Page_Pagefieldsgroup_Components_CardsLayout: (data) => (
			<CardLayout
				heading={data?.heading}
				button={data?.link}
				services={data?.services}
			/>
		),
		Page_Pagefieldsgroup_Components_CardsLayout2: (data) => (
			<CardLayout2
				heading={data?.heading}
				button={data?.link}
				content={data?.description}
				items={data?.items}
				learnMoreLink={data?.learnMoreLink}
			/>
		),
		Page_Pagefieldsgroup_Components_CustomerTestimonial: (data) => (
			<CustomerTestimonials
				heading={data?.heading}
				content={data?.description}
				overlayColor={data?.overlayColor}
				testimonials={reviews?.reviews}
				bgImage={
					data.backgroundImage?.localFile?.childImageSharp?.gatsbyImageData
				}
			/>
		),
		Page_Pagefieldsgroup_Components_Image: (data) => (
			<GatsbyImage
				image={data?.image?.localFile?.childImageSharp?.gatsbyImageData}
				alt={data?.image?.altText}
				className="w-100 h-auto"
				style={{ minHeight: "450px" }}
			/>
		),
		Page_Pagefieldsgroup_Components_ContactLayout: (data) => (
			<ContactLayout
				heading={data?.heading}
				content={data?.description}
				items={data?.contactItem}
			/>
		),
		Page_Pagefieldsgroup_Components_ContactLayout2: (data) => (
			<ContactLayout2 heading={data?.heading} content={data?.description} />
		),
		Page_Pagefieldsgroup_Components_SectionLayout: (data) => (
			<SectionLayout heading={data?.heading} content={data?.description} />
		),
		Page_Pagefieldsgroup_Components_SectionLayout2: (data) => (
			<SectionLayout2
				heading={data?.heading}
				content={data?.description}
				overlayColor={data?.overlayColor}
				bgImage={
					data.backgroundImage?.localFile?.childImageSharp?.gatsbyImageData
				}
				image={data.image?.localFile?.childImageSharp?.gatsbyImageData}
				backgroundColor={data?.backgroundColor}
				button={data?.link}
			/>
		),
		Page_Pagefieldsgroup_Components_SectionLayout3: (data) => (
			<SectionLayout3
				tagline={data?.tagline}
				heading={data?.heading}
				content={data?.description}
				button={data?.link}
			/>
		),
		Page_Pagefieldsgroup_Components_SectionLayout4: (data) => (
			<SectionLayout4
				heading={data?.heading}
				content={data?.description}
				overlayColor={data?.overlayColor}
				items={data?.items}
				bgImage={
					data?.backgroundImage?.localFile?.childImageSharp?.gatsbyImageData
				}
			/>
		),
		Page_Pagefieldsgroup_Components_SectionLayout5: (data) => (
			<SectionLayout5 teams={data.teams} note={data.note} />
		),
		Page_Pagefieldsgroup_Components_CtaBanner: (data) => (
			<CtaBanner
				heading={data?.heading}
				button={data?.link}
				content={data?.description}
				backgroundColor={data?.backgroundColor}
				image={data?.icon?.localFile?.childImageSharp?.gatsbyImageData}
			/>
		),
		Page_Pagefieldsgroup_Components_BlogListing: (data) => (
			<BlogListing
				tagline={data?.tagline}
				heading={data?.heading}
				content={data?.description}
				posts={allWpPost?.nodes}
			/>
		),
		Page_Pagefieldsgroup_Components_RelatedBlogs: (data) => (
			<RecommendedBlogs
				tagline={data?.tagline}
				heading={data?.heading}
				content={data?.description}
				button={data?.link}
				posts={data?.blogs}
			/>
		),
		Page_Pagefieldsgroup_Components_BlogListingAll: (data) => (
			<BlogListingAll
				tagline={data?.tagline}
				heading={data?.heading}
				content={data?.description}
				posts={allWpPost?.nodes}
			/>
		),
		Page_Pagefieldsgroup_Components_OurTeam: (data) => (
			<OurTeam
				teams={data.teams}
				heading={data.heading}
				hiringDescription={data?.hiringDescription}
				hiringTitle={data?.hiringTitle}
				hiringLink={data?.hiringLink}
			/>
		),
    Page_Pagefieldsgroup_Components_CareerListing: (data) => (
			<CareerListing
        positions={data.positions}
			/>
		),
	};

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={wpPage?.seoFields?.metaTitle || wpPage?.title}
				description={wpPage?.seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/${wpPage.slug}`,
					title: `${wpPage.seoFields?.opengraphTitle || wpPage?.title}`,
					description: `${wpPage.seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${wpPage.seoFields?.image?.sourceUrl}`,
							width: `${wpPage.seoFields?.image?.mediaDetails.width}`,
							height: `${wpPage.seoFields?.image?.mediaDetails.height}`,
							alt: `${wpPage.seoFields?.image?.altText}`,
						},
					],
				}}
			/>
			{wpPage.pageFieldsGroup.components.map((component, index) => {
				const renderComponent = componentMap[component.fieldGroupName];
				return renderComponent ? (
					<div key={index}>{renderComponent(component)}</div>
				) : null;
			})}
		</Layout>
	);
};

export default PageTemplate;

export const pageQuery = graphql`
	query HomeById {
		site {
			siteMetadata {
				siteUrl
			}
		}
		reviews {
			reviewCount
			reviews {
				author
				datePublished
				source
				reviewBody
			}
		}
		allWpPost(sort: { order: DESC, fields: date }) {
			nodes {
				title
				slug
				blogField {
					content
					excerpt
					readTime
					fieldGroupName
					featureImage {
						altText
						sourceUrl
						localFile {
							childImageSharp {
								gatsbyImageData(
									formats: [WEBP, AUTO]
									quality: 100
									transformOptions: { cropFocus: CENTER, fit: COVER }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
							}
						}
					}
				}
			}
		}
		wpPage(slug: { eq: "homepage" }) {
			slug
			title
			seoFields {
				opengraphTitle
				opengraphDescription
				metaTitle
				metaDescription
				fieldGroupName
				productSchema
				image {
					altText
					sourceUrl
					mediaDetails {
						height
						width
					}
				}
			}
			pageFieldsGroup {
				components {
					... on WpPage_Pagefieldsgroup_Components_Header1 {
						description
						fieldGroupName
						heading
						image {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
						link {
							target
							title
							url
						}
						logos {
							icon {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [WEBP, AUTO]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					... on WpPage_Pagefieldsgroup_Components_Header2 {
						fieldGroupName
						heading
						link {
							target
							title
							url
						}
						overlayColor
						backgroundImage {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpPage_Pagefieldsgroup_Components_CustomerTestimonial {
						description
						fieldGroupName
						heading
						overlayColor
						backgroundImage {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpPage_Pagefieldsgroup_Components_CardsLayout {
						fieldGroupName
						heading
						link {
							target
							title
							url
						}
						services {
							... on WpService {
								id
								slug
								serviceFields {
									description
									icon {
										altText
										sourceUrl
										localFile {
											childImageSharp {
												gatsbyImageData(
													formats: [WEBP, AUTO]
													quality: 100
													transformOptions: { cropFocus: CENTER, fit: COVER }
													layout: CONSTRAINED
													placeholder: BLURRED
													width: 64
												)
											}
										}
									}
									name
								}
							}
						}
					}
					... on WpPage_Pagefieldsgroup_Components_Image {
						fieldGroupName
						image {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpPage_Pagefieldsgroup_Components_CardsLayout2 {
						centerAlign
						description
						heading
						fieldGroupName
						items {
							description
							heading
							icon {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [WEBP, AUTO]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
											height: 64
										)
									}
								}
							}
						}
						link {
							target
							title
							url
						}
						learnMoreLink {
							target
							title
							url
						}
					}
					... on WpPage_Pagefieldsgroup_Components_ContactLayout2 {
						description
						fieldGroupName
						heading
					}
					... on WpPage_Pagefieldsgroup_Components_ContactLayout {
						description
						fieldGroupName
						heading
						contactItem {
							fieldGroupName
							heading
							isEmergency
							subheading
							icon {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [WEBP, AUTO]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
											height: 64
										)
									}
								}
							}
							link {
								target
								title
								url
							}
						}
					}
					... on WpPage_Pagefieldsgroup_Components_SectionLayout {
						description
						fieldGroupName
						heading
					}
					... on WpPage_Pagefieldsgroup_Components_SectionLayout2 {
						backgroundColor
						description
						fieldGroupName
						heading
						overlayColor
						tagline
						backgroundImage {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
						image {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
						link {
							target
							title
							url
						}
					}
					... on WpPage_Pagefieldsgroup_Components_SectionLayout3 {
						backgroundColor
						description
						fieldGroupName
						heading
						tagline
						link {
							target
							title
							url
						}
					}
					... on WpPage_Pagefieldsgroup_Components_SectionLayout4 {
						description
						fieldGroupName
						heading
						overlayColor
						items {
							fieldGroupName
							title
							description
						}
						backgroundImage {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpPage_Pagefieldsgroup_Components_SectionLayout5 {
						fieldGroupName
						note
						teams {
							... on WpTeam {
								teamsFields {
									email
									fieldGroupName
									linkedinUrl
									location
									name
									phone
									role
									roleType
									twitterUrl
									description
									profileImage {
										altText
										sourceUrl
										localFile {
											childImageSharp {
												gatsbyImageData(
													formats: [WEBP, AUTO]
													quality: 100
													transformOptions: { cropFocus: CENTER, fit: COVER }
													layout: CONSTRAINED
													placeholder: BLURRED
													width: 80
												)
											}
										}
									}
								}
								title
							}
						}
					}
					... on WpPage_Pagefieldsgroup_Components_BlogListing {
						description
						fieldGroupName
						heading
						tagline
					}
					... on WpPage_Pagefieldsgroup_Components_BlogListingAll {
						description
						fieldGroupName
						heading
						tagline
					}
					... on WpPage_Pagefieldsgroup_Components_RelatedBlogs {
						description
						fieldGroupName
						heading
						tagline
						blogs {
							... on WpPost {
								id
								title
								slug
								blogField {
									content
									excerpt
									readTime
									fieldGroupName
									featureImage {
										altText
										sourceUrl
										localFile {
											childImageSharp {
												gatsbyImageData(
													formats: [WEBP, AUTO]
													quality: 100
													transformOptions: { cropFocus: CENTER, fit: COVER }
													layout: CONSTRAINED
													placeholder: BLURRED
												)
											}
										}
									}
								}
							}
						}
						link {
							target
							title
							url
						}
					}
					... on WpPage_Pagefieldsgroup_Components_OurTeam {
						fieldGroupName
						heading
						hiringDescription
						hiringTitle
						hiringLink {
							target
							title
							url
						}
						teams {
							... on WpTeam {
								teamsFields {
									email
									fieldGroupName
									linkedinUrl
									location
									name
									phone
									role
									roleType
									twitterUrl
									description
									profileImage {
										altText
										sourceUrl
										localFile {
											childImageSharp {
												gatsbyImageData(
													formats: [WEBP, AUTO]
													quality: 100
													transformOptions: { cropFocus: CENTER, fit: COVER }
													layout: CONSTRAINED
													placeholder: BLURRED
													width: 80
												)
											}
										}
									}
								}
								title
							}
						}
					}
					... on WpPage_Pagefieldsgroup_Components_CtaBanner {
						backgroundColor
						description
						fieldGroupName
						heading
						icon {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
										height: 64
									)
								}
							}
						}
						link {
							target
							title
							url
						}
					}
					... on WpPage_Pagefieldsgroup_Components_CareerListing {
						fieldGroupName
						positions {
						  ... on WpPosition {
							id
							positionFields {
							  description
							  fieldGroupName
							  positionsInfo {
								title
							  }
							  positionShortDesc
							  positionDetail {
								content
								title
							  }
							  applyCta {
								target
								title
								url
							  }
							}
							title
						  }
						}
					}
				}
			}
		}
	}
`;
